import { FC, useContext, useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../hooks/use-auth';
import { Cog as CogIcon } from '../../icons/cog';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { SwitchHorizontalOutlined as SwitchHorizontalOutlinedIcon } from '../../icons/switch-horizontal-outlined';
import { User as UserType } from 'src/types/user';
import UserAvatar from '../user-avatar';
import { UserContext } from 'src/contexts/user-context';
import { Settings } from 'src/contexts/settings-context';
import { useSettings } from 'src/hooks/use-settings';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { alpha } from '@mui/material/styles';
import AppCan from 'src/contexts/app-ability-context';
import { EventTrackerCtx } from 'src/contexts/event-tracker';
import client from 'src/lib/apollo-client';
import { ActivityLogCtx } from 'src/contexts/activity-log';
import { ActivityLog } from 'src/types/activity-log';
import { ActivityLogEventType } from '../../../../../libs/shared-resources/src/lib/activity-log';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  user?: UserType;
}

const themes = [
  {
    label: 'Light',
    value: 'light',
    icon: <LightModeOutlinedIcon />,
  },
  {
    label: 'System',
    value: 'system',
    icon: <SettingsBrightnessIcon />,
  },
  {
    label: 'Dark',
    value: 'dark',
    icon: <DarkModeOutlinedIcon />,
  },
];

const getValues = (settings: Settings) => ({
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  theme: settings.theme,
});

export const AccountPopover: FC<AccountPopoverProps> = props => {
  const { anchorEl, onClose, open, user, ...other } = props;
  const router = useRouter();
  const { logout } = useAuth();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { reset } = useContext(EventTrackerCtx);
  const { createLog } = useContext(ActivityLogCtx);

  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState<Settings>(getValues(settings));

  const handleLogout = async (): Promise<void> => {
    try {
      onClose?.();
      //Activity log track
      const activityLog: ActivityLog = {
        subject: 'User',
        subjectRef: currentUser?._id.toString(),
        claimNumber: null,
        gqlOperation: '',
        eventType: ActivityLogEventType.Logout,
        event: `User ${currentUser?._id.toString()} logged out successfully`,
      };
      await createLog(activityLog, true);

      await logout();

      router.push('/authentication/login').catch(console.error);
      setCurrentUser({});
      await client.clearStore();
      reset();
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  useEffect(() => {
    values?.theme !== settings.theme && saveSettings(values);
  }, [values]);

  const handleThemeChange = (value: unknown): void => {
    setValues({
      ...values,
      theme: value as 'light' | 'dark' | 'system',
    });
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        <UserAvatar name={`${user?.firstName || ''} ${user?.lastName || ''}`} />
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">
            {user.firstName} {user.middleName} {user.lastName}
          </Typography>
          {user.company && (
            <Typography color="textSecondary" variant="body2">
              {user.company}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <NextLink href="/profile" passHref>
          <MenuItem component="a">
            <ListItemIcon>
              <UserCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Profile</Typography>}
            />
          </MenuItem>
        </NextLink>
        <AppCan do="manage" on="User">
          <NextLink href="/admin/claims/status-board" passHref>
            <MenuItem component="a">
              <ListItemIcon>
                <SupervisorAccountIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">Admin Dashboard</Typography>
                }
              />
            </MenuItem>
          </NextLink>
        </AppCan>
        {/* <NextLink href="/dashboard/account" passHref>
          <MenuItem component="a">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Settings</Typography>}
            />
          </MenuItem>
        </NextLink>
        <NextLink href="/dashboard" passHref>
          <MenuItem component="a">
            <ListItemIcon>
              <SwitchHorizontalOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">Change organization</Typography>
              }
            />
          </MenuItem>
        </NextLink>
        <Divider /> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Logout</Typography>}
          />
        </MenuItem>

        <Divider />
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            color="textSecondary"
            sx={{
              display: 'block',
              alignSelf: 'start',
              fontSize: '0.7rem',
            }}
            variant={'overline'}
          >
            Theme:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              p: 1,
              alignItems: 'center',
            }}
          >
            {themes.map((theme, index) => {
              const { label, icon, value } = theme;
              const selected = values.theme === value;
              return (
                <Box
                  key={label}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 'small',
                    border: '1px solid',
                    borderColor: selected ? 'primary.main' : 'divider',
                    backgroundColor: theme =>
                      selected && alpha(theme.palette.primary.main, 0.2),
                    p: 1,
                    borderRadius:
                      index === 0 // first item
                        ? '10px 0 0 10px'
                        : index === themes.length - 1 && // last item
                        '0 10px 10px 0',
                  }}
                  onClick={() => {
                    handleThemeChange(value);
                  }}
                >
                  {icon}
                  <Box sx={{ px: 1 }}>{label}</Box>
                </Box>
              );
            })}
          </Box>
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
