import { useRef, useState, useContext } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  ButtonBase,
  IconButton,
  Toolbar,
  Container,
  GlobalStyles,
  Skeleton,
  Typography,
  Tooltip,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import { Menu as MenuIcon } from '../../icons/menu';
import { Bell as BellIcon } from '../../icons/bell';
import { Search as SearchIcon } from '../../icons/search';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { Users as UsersIcon } from '../../icons/users';
import { AccountPopover } from './account-popover';
import { UserContext } from 'src/contexts/user-context';
import UserAvatar from '../user-avatar';
import NextLink from 'next/link';
import Image from 'next/image';
import { getHours } from 'date-fns';
import { SidebarCtx } from 'src/contexts/sidebar-context';
import _ from 'lodash';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
// import { ContactsPopover } from './contacts-popover';
// import { ContentSearchDialog } from './content-search-dialog';
// import { NotificationsPopover } from './notifications-popover';
// import { LanguagePopover } from './language-popover';

interface NavbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
}

type Language = 'en' | 'de' | 'es';

// const languages: Record<Language, string> = {
//   en: '/static/icons/uk_flag.svg',
//   de: '/static/icons/de_flag.svg',
//   es: '/static/icons/es_flag.svg',
// };

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.navigation.main,
  // ...(theme.palette.mode === 'light'
  //   ? {
  //       boxShadow: theme.shadows[3],
  //     }
  //   : {
  //       backgroundColor: theme.palette.background.paper,
  //       borderBottomColor: theme.palette.divider,
  //       borderBottomStyle: 'solid',
  //       borderBottomWidth: 1,
  //       boxShadow: 'none',
  //     }),
}));

// const LanguageButton = () => {
//   const anchorRef = useRef<HTMLButtonElement | null>(null);
//   const { i18n } = useTranslation();
//   const [openPopover, setOpenPopover] = useState<boolean>(false);

//   const handleOpenPopover = (): void => {
//     setOpenPopover(true);
//   };

//   const handleClosePopover = (): void => {
//     setOpenPopover(false);
//   };

//   return (
//     <>
//       <IconButton onClick={handleOpenPopover} ref={anchorRef} sx={{ ml: 1 }}>
//         <Box
//           sx={{
//             display: 'flex',
//             height: 20,
//             width: 20,
//             '& img': {
//               width: '100%',
//             },
//           }}
//         >
//           <img alt="" src={languages[i18n.language as Language]} />
//         </Box>
//       </IconButton>
//       <LanguagePopover
//         anchorEl={anchorRef.current}
//         onClose={handleClosePopover}
//         open={openPopover}
//       />
//     </>
//   );
// };

// const ContentSearchButton = () => {
//   const [openDialog, setOpenDialog] = useState<boolean>(false);

//   const handleOpenSearchDialog = (): void => {
//     setOpenDialog(true);
//   };

//   const handleCloseSearchDialog = (): void => {
//     setOpenDialog(false);
//   };

//   return (
//     <>
//       <Tooltip title="Search">
//         <IconButton onClick={handleOpenSearchDialog} sx={{ ml: 1 }}>
//           <SearchIcon fontSize="small" />
//         </IconButton>
//       </Tooltip>
//       <ContentSearchDialog
//         onClose={handleCloseSearchDialog}
//         open={openDialog}
//       />
//     </>
//   );
// };

// const ContactsButton = () => {
//   const anchorRef = useRef<HTMLButtonElement | null>(null);
//   const [openPopover, setOpenPopover] = useState<boolean>(false);

//   const handleOpenPopover = (): void => {
//     setOpenPopover(true);
//   };

//   const handleClosePopover = (): void => {
//     setOpenPopover(false);
//   };

//   return (
//     <>
//       <Tooltip title="Contacts">
//         <IconButton onClick={handleOpenPopover} sx={{ ml: 1 }} ref={anchorRef}>
//           <UsersIcon fontSize="small" />
//         </IconButton>
//       </Tooltip>
//       <ContactsPopover
//         anchorEl={anchorRef.current}
//         onClose={handleClosePopover}
//         open={openPopover}
//       />
//     </>
//   );
// };

// const NotificationsButton = () => {
//   const anchorRef = useRef<HTMLButtonElement | null>(null);
//   const [unread, setUnread] = useState<number>(0);
//   const [openPopover, setOpenPopover] = useState<boolean>(false);
//   // Unread notifications should come from a context and be shared with both this component and
//   // notifications popover. To simplify the demo, we get it from the popover

//   const handleOpenPopover = (): void => {
//     setOpenPopover(true);
//   };

//   const handleClosePopover = (): void => {
//     setOpenPopover(false);
//   };

//   const handleUpdateUnread = (value: number): void => {
//     setUnread(value);
//   };

//   return (
//     <>
//       <Tooltip title="Notifications">
//         <IconButton ref={anchorRef} sx={{ ml: 1 }} onClick={handleOpenPopover}>
//           <Badge color="error" badgeContent={unread}>
//             <BellIcon fontSize="small" />
//           </Badge>
//         </IconButton>
//       </Tooltip>
//       <NotificationsPopover
//         anchorEl={anchorRef.current}
//         onClose={handleClosePopover}
//         onUpdateUnread={handleUpdateUnread}
//         open={openPopover}
//       />
//     </>
//   );
// };

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  // const user = {
  //   avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
  //   name: 'Anika Visser',
  // };

  const { currentUser, loadingUser } = useContext(UserContext);

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  // if (!user) {
  //   return <SplashScreen />;
  // }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'end',
          display: 'flex',
          width: '100%',
          ml: 2,
        }}
      >
        <NextLink href="/claims" passHref>
          <Tooltip title="Home">
            <IconButton sx={{ color: 'white', mx: 2 }}>
              <CottageOutlinedIcon sx={{ fontSize: 28 }} />
            </IconButton>
          </Tooltip>
        </NextLink>
        <Box component={ButtonBase} onClick={handleOpenPopover} ref={anchorRef}>
          {currentUser && !loadingUser ? (
            <UserAvatar
              name={`${currentUser?.firstName || ''} ${
                currentUser?.lastName || ''
              }`}
            />
          ) : (
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ backgroundColor: '#ffffff14' }}
            />
          )}
        </Box>
      </Box>
      {currentUser && (
        <AccountPopover
          anchorEl={anchorRef.current}
          onClose={handleClosePopover}
          open={openPopover}
          user={currentUser}
        />
      )}
    </>
  );
};

export const Navbar: FC<NavbarProps> = props => {
  const { onOpenSidebar, ...other } = props;
  const { currentUser, loadingUser } = useContext(UserContext);
  const sidebarContext = useContext(SidebarCtx);
  const theme = useTheme();

  const welcomeMessage = (): string => {
    const hours = getHours(new Date());
    const time =
      hours < 12
        ? 'morning'
        : hours >= 12 && hours < 18
        ? 'afternoon'
        : hours >= 18 && 'evening';

    return `Good ${time}, ${currentUser?.firstName} ${currentUser?.lastName}!`;
  };

  return (
    <>
      {/* Add a scrollto offset when the header is there  */}
      <GlobalStyles styles={{ html: { scrollPaddingTop: '100px' } }} />
      <NavbarRoot
        // position="sticky"
        // sx={{
        //   left: {
        //     lg: 280,
        //   },
        //   width: {
        //     lg: 'calc(100% - 280px)',
        //   },
        // }}
        {...other}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              minHeight: 64,
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {!sidebarContext && (
              <Box
                sx={{
                  width: 300,
                }}
              >
                <NextLink href="/claims" passHref>
                  <Box>
                    <Image
                      src="/static/logo-nav.svg"
                      alt="logo"
                      width={'130px'}
                      height={'50px'}
                      layout="intrinsic"
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                </NextLink>
              </Box>
            )}

            {sidebarContext && (
              <IconButton
                onClick={onOpenSidebar}
                sx={{
                  display: {
                    xs: 'inline-flex',
                    lg: 'none',
                  },
                }}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
            )}

            {/* <LanguageButton /> */}
            {/* <ContentSearchButton /> */}
            {/* <ContactsButton /> */}
            {/* <NotificationsButton /> */}

            {currentUser && !_.isEmpty(currentUser) && !loadingUser && (
              <Typography
                noWrap
                variant="subtitle1"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                  },
                  pl: sidebarContext?.isSidebarOpen
                    ? { xs: 30, lg: 35 }
                    : { xs: 3, lg: 8 },
                  transition: theme.transitions.create('padding', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                  width: '100%',
                }}
              >
                {welcomeMessage()}
              </Typography>
            )}

            <AccountButton />
          </Toolbar>
        </Container>
      </NavbarRoot>
    </>
  );
};

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
