import { Box, Divider, Skeleton } from '@mui/material';
import React from 'react';
import Image from 'next/image';

const SidebarSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'navigation.dark',
        width: 280,
      }}
    >
      {/* <Skeleton
        variant="rectangular"
        width={180}
        height={50}
        sx={{
          backgroundColor: '#ffffff14',
          m: 2,
          mb: 5,
          borderRadius: 1,
        }}
      /> */}
      <Box sx={{ m: 3 }}>
        <Image
          src="/static/logo-nav.svg"
          alt="logo"
          width={'130px'}
          height={'50px'}
          layout="intrinsic"
          // style={{ textAlign: 'left' }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
        }}
      >
        <Skeleton
          variant="rectangular"
          width={80}
          sx={{ backgroundColor: '#ffffff14', mr: 2, borderRadius: 1 }}
        />
        <Skeleton
          variant="rectangular"
          width={90}
          sx={{ backgroundColor: '#ffffff14', borderRadius: 1 }}
        />
      </Box>

      <Skeleton
        variant="rectangular"
        width={200}
        sx={{ backgroundColor: '#ffffff14', mx: 4, borderRadius: 1 }}
      />
      <Divider
        sx={{
          borderColor: '#2D3748', // dark divider
          my: 5,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 5,
          p: 1,
        }}
      >
        <Box>
          <Skeleton
            variant="rectangular"
            width={250}
            height={50}
            sx={{ backgroundColor: '#ffffff14', borderRadius: 1, mb: 5 }}
          />
          <Skeleton
            variant="rectangular"
            width={150}
            sx={{ backgroundColor: '#ffffff14', borderRadius: 1, mb: 3 }}
          />
          <Skeleton
            variant="rectangular"
            width={150}
            sx={{ backgroundColor: '#ffffff14', borderRadius: 1, mb: 3 }}
          />
          <Skeleton
            variant="rectangular"
            width={150}
            sx={{ backgroundColor: '#ffffff14', borderRadius: 1, mb: 1 }}
          />
        </Box>
        <Box>
          <Skeleton
            variant="rectangular"
            width={250}
            height={50}
            sx={{ backgroundColor: '#ffffff14', borderRadius: 1, my: 5 }}
          />
        </Box>
        <Box>
          <Skeleton
            variant="rectangular"
            width={250}
            height={50}
            sx={{ backgroundColor: '#ffffff14', borderRadius: 1 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarSkeleton;
