import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import type { AvatarProps } from '@mui/material/Avatar';
import { Box, Tooltip } from '@mui/material';

interface UserAvatarProps extends AvatarProps {
  name: string;
  imgSrc?: string;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, props: any) {
  const words = name?.split(' ');
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...props?.sx,
    },
    children:
      words?.length && words.map(word => word[0]?.toUpperCase()).join(''),
  };
}

export const UserAvatar: FC<UserAvatarProps> = props => {
  const { name, imgSrc } = props;

  return (
    <Avatar alt={name} src={imgSrc} {...props} {...stringAvatar(name, props)} />
  );
};

export const UserAvatarTooltip: FC<UserAvatarProps> = props => {
  const { name } = props;

  return (
    <Tooltip arrow title={name}>
      <Box>
        <UserAvatar {...props} />
      </Box>
    </Tooltip>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
};

export default UserAvatarTooltip;
