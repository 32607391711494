import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { ListProps } from '@mui/material';
import { List, ListSubheader } from '@mui/material';
import { SidebarItem } from './sidebar-item';

interface Item {
  path?: string;
  icon?: ReactNode;
  chip?: ReactNode;
  extraInfo?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  disabled?: boolean;
  matchType?: 'partial' | 'exact';
}

interface SidebarSectionProps extends ListProps {
  items: Item[];
  path: string;
  title: string;
  drawerOpen?: boolean;
}

const renderNavItems = ({
  depth = 0,
  items,
  path,
  drawerOpen,
}: {
  depth?: number;
  items: Item[];
  path: string;
  drawerOpen: boolean;
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      (acc: JSX.Element[], item) =>
        reduceChildRoutes({ acc, depth, item, path, drawerOpen }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  path,
  drawerOpen,
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  path: string;
  drawerOpen: boolean;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  //@TODO: Find a better way to mark sections as active (better path match)
  const partialMatch = item.path ? path.includes(item.path) : false;
  const pathMatch =
    item.path && item.matchType === 'partial'
      ? //partialMatch
        path.includes(item?.path)
      : //exactMatch
      item.children
      ? path.split('?')[0].split('#')[0] === item.path // If Branch (father) element we don't compare anchor
      : path.split('?')[0] === item.path; // We don't compare query params

  if (item.children) {
    acc.push(
      <SidebarItem
        active={pathMatch}
        disabled={item.disabled}
        chip={item.chip}
        extraInfo={item.extraInfo}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={pathMatch}
        path={item.path}
        title={item.title}
      >
        {drawerOpen &&
          renderNavItems({
            depth: depth + 1,
            items: item.children,
            path,
          })}
      </SidebarItem>,
    );
  } else {
    acc.push(
      <SidebarItem
        active={pathMatch}
        disabled={item.disabled}
        chip={item.chip}
        extraInfo={item.extraInfo}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />,
    );
  }

  return acc;
};

export const SidebarSection: FC<SidebarSectionProps> = props => {
  const { items, path, title, drawerOpen = true, ...other } = props;

  return (
    <List {...other}>
      {renderNavItems({
        items,
        path,
        drawerOpen,
      })}
    </List>
  );
};

SidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
