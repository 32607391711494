import {
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import type { FC } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  IconButton,
  Stack,
  Drawer,
  CSSObject,
  styled,
} from '@mui/material';
import {
  DescriptionOutlined,
  QuestionAnswerOutlined,
  BoltOutlined,
} from '@mui/icons-material';
import type { Theme } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';
import { Scrollbar } from '../scrollbar';
import { ClaimCtx } from 'src/contexts/claim-context';
import Image from 'next/image';
import MuiDrawer from '@mui/material/Drawer';
import { SidebarSection } from './sidebar-section';
import SidebarSkeleton from '../skeletons/sidebar-skeleton';

interface SidebarProps {
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  toggle?: () => void;
  sections?: Section[];
  infoPanel?: React.ReactNode;
  loading?: boolean;
}

interface Item {
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  disabled?: boolean;
}

interface Section {
  title: string;
  items: Item[];
}

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const MiniDrawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const Sidebar: FC<SidebarProps> = props => {
  const { onClose, open, toggle, onOpen, sections, infoPanel, loading } = props;
  const [fixedOpen, setFixedOpen] = useState<boolean>(true);
  const router = useRouter();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const organizationsRef = useRef<HTMLButtonElement | null>(null);
  const [openOrganizationsPopover, setOpenOrganizationsPopover] =
    useState<boolean>(false);

  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }

    // if (open) {
    //   onClose?.();
    // }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, router.asPath],
  );

  const handleOpenOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(true);
  };

  const handleCloseOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(false);
  };

  useEffect(() => {
    !open && setFixedOpen(false);
  }, [open]);

  const content = loading ? (
    <SidebarSkeleton />
  ) : (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box sx={{ px: 2, py: 3 }}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignContent="middle"
            >
              {open && (
                <NextLink href="/claims" passHref>
                  <a>
                    <Image
                      src="/static/logo-nav.svg"
                      alt="logo"
                      width={'130px'}
                      height={'50px'}
                      layout="intrinsic"
                      // style={{ textAlign: 'left' }}
                    />
                  </a>
                </NextLink>
              )}

              <IconButton
                onClick={() => {
                  !open ? setFixedOpen(true) : setFixedOpen(false);
                  toggle && toggle();
                }}
              >
                {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Stack>
          </Box>
          {/* ORG SWITCHER */}
          {open && infoPanel && infoPanel}

          <Divider
            sx={{
              borderColor: '#2D3748', // dark divider
              my: open ? 3 : 0,
            }}
          />
          <Box sx={{ flexGrow: 1, mb: 10 }} onMouseOver={onOpen}>
            {sections?.map(section => (
              <SidebarSection
                key={section.title}
                path={router.asPath}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2,
                  },
                }}
                drawerOpen={open}
                {...section}
              />
            ))}
          </Box>
          <Divider
            sx={{
              borderColor: '#2D3748', // dark divider
            }}
          />
        </Box>
      </Scrollbar>
      {/* <OrganizationPopover
          anchorEl={organizationsRef.current}
          onClose={handleCloseOrganizationsPopover}
          open={openOrganizationsPopover}
        /> */}
    </>
  );

  if (lgUp) {
    return (
      <MiniDrawer
        anchor="left"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: theme => theme.palette.navigation.dark,
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: theme => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            zIndex: theme => theme.zIndex.appBar + 100,
            // width: 280,
          },
        }}
        variant="permanent"
        onMouseLeave={() => {
          !fixedOpen && onClose && onClose();
        }}
        onClose={() => {
          console.log('ONCLOSED');
          setFixedOpen(false);
        }}
      >
        {content}
      </MiniDrawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: theme => theme.palette.navigation.dark,
          color: '#FFFFFF',
          width: drawerWidth,
        },
      }}
      sx={{ zIndex: theme => theme.zIndex.appBar + 100 }}
    >
      {content}
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
