import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import type { ListItemProps } from '@mui/material';

interface SidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  extraInfo?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  disabled?: boolean;
  title: string;
}

export const SidebarItem: FC<SidebarItemProps> = props => {
  const {
    active,
    children,
    chip,
    extraInfo,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    disabled,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(!!openProp);

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };
  let paddingLeft = 10;

  if (depth > 0) {
    paddingLeft = 16 * depth;
  }

  // Branch
  if (children || depth === 0) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          // mb: 0.5,
          py: 0,
          px: 0,
        }}
        {...other}
      >
        <NextLink href={path as string} passHref>
          <ListItemButton
            disableRipple
            onClick={handleToggle}
            disabled={disabled}
            sx={{
              // color: active ? 'green' : 'red',
              color: 'neutral.200',
              justifyContent: 'flex-start',
              pl: `${paddingLeft}px`,
              px: 2,
              py: 2,
              textAlign: 'left',
              textTransform: 'none',
              width: '100%',
              borderRadius: 0,
              backgroundColor: active ? 'primary.main' : 'transparent',
              '&:hover': {
                backgroundColor: active ? 'primary.main' : 'transparent',
                opacity: 0.8,
              },
              '& .MuiButton-startIcon': {
                color: active ? 'neutral.200' : 'neutral.400',
              },
              '& .MuiButton-endIcon': {
                color: 'neutral.400',
              },
              '&.Mui-disabled': {
                color: 'gray',
              },
            }}
          >
            <ListItemIcon sx={{ color: 'neutral.200' }}>{icon}</ListItemIcon>
            <Box sx={{ flexGrow: 1, ml: 1 }}>
              <Typography variant="h6">
                {title}&nbsp;
                {extraInfo}
              </Typography>

            </Box>
            {info}
          </ListItemButton>
        </NextLink>
        {children && active && (
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
              mt: -1,
              py: 2,
            }}
          >
            {children}
          </Box>
        )}
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        mt: 1,
        py: 0,
        px: 1,
      }}
    >
      <NextLink href={path as string} passHref>
        <Button
          component="a"
          disabled={disabled}
          startIcon={icon}
          endIcon={chip}
          disableRipple
          sx={{
            borderRadius: 1,
            color: 'neutral.300',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            ...(active && {
              backgroundColor: 'rgba(255,255,255, 0.08)',
              color: 'secondary.main',
              fontWeight: 'fontWeightBold',
            }),
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.400',
            },
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)',
            },
            '&.Mui-disabled': {
              color: 'gray',
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
      </NextLink>
    </ListItem>
  );
};

SidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SidebarItem.defaultProps = {
  active: false,
  open: false,
};
