import { Box, Divider, Skeleton, useMediaQuery } from '@mui/material';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { SidebarLayout } from 'src/components/layouts/sidebar-layout';
import ClaimDetailsSkeleton from 'src/components/skeletons/claims/claim-details-skeleton';
import SidebarSkeleton from 'src/components/skeletons/sidebar-skeleton';
import { ClaimCtx } from './claim-context';

export const SidebarCtx = createContext<any>(null);

interface Item {
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  disabled?: boolean;
}

interface Section {
  title: string;
  items: Item[];
}

interface ISidebarWrapper {
  children?: ReactNode;
  sections?: Section[];
  loading?: boolean;
  infoPanel?: ReactNode;
}

export const SidebarWrapper = ({
  children,
  sections,
  infoPanel,
  loading = false,
}: ISidebarWrapper) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(lgUp);

  return (
    <SidebarCtx.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen,
      }}
    >
      <SidebarLayout
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={(open: boolean) => setIsSidebarOpen(open)}
        sections={sections}
        loading={loading}
        infoPanel={infoPanel}
      >
        {children}
      </SidebarLayout>
    </SidebarCtx.Provider>
  );
};
