import { FC, ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Navbar } from '../navbar';
import { Box } from '@mui/material';
import { AdminSidebar } from './sidebar/admin-sidebar';
import { Sidebar } from '../sidebar/sidebar';
import GroupIcon from '@mui/icons-material/Group';
import { t } from 'i18next';

interface Item {
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  disabled?: boolean;
}

interface Section {
  title: string;
  items: Item[];
}

interface SidebarLayoutProps {
  children?: ReactNode;
  sections?: Section[];
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
  loading?: boolean;
  infoPanel?: ReactNode;
}

const SidebarLayoutRoot = styled('div')<{
  isSidebarOpen?: boolean;
}>(({ theme, isSidebarOpen }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  // paddingTop: 64,
  transition: theme.transitions.create('padding', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: isSidebarOpen ? 280 : 65,
  },
}));

export const SidebarLayout: FC<SidebarLayoutProps> = props => {
  const {
    children,
    sections,
    isSidebarOpen,
    setIsSidebarOpen,
    loading,
    infoPanel,
  } = props;

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <>
      <SidebarLayoutRoot isSidebarOpen={isSidebarOpen}>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </SidebarLayoutRoot>
      <Navbar
        onOpenSidebar={(): void => setIsSidebarOpen(true)}
        // sx={{
        //   left: {
        //     lg: 280,
        //   },
        //   width: {
        //     lg: 'calc(100% - 280px)',
        //   },
        // }}
      />
      <Sidebar
        onClose={(): void => setIsSidebarOpen(false)}
        onOpen={(): void => setIsSidebarOpen(true)}
        open={isSidebarOpen}
        toggle={() => setIsSidebarOpen(!isSidebarOpen)}
        sections={sections}
        loading={loading}
        infoPanel={infoPanel}
      />
    </>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node,
};
